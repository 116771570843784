import React, { useState } from 'react';
import { MdCheck, MdArrowForward, MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md";

const forms = [
  {
    "type": "plain_text",
    "title": "¿Cómo podemos ayudarte? Será importante que nos lo diga",
    "description": "Rellena el siguiente formulario",
    "hint_text": "Escriba aquí su respuesta...",
    "button_text": "Aceptar"
  },
  {
    "type": "plain_text",
    "title": "¿Cuándo estás disponible? También nos resulta importante saberlo.",
    "description": "Pon un intervalo de horas a continuación",
    "hint_text": "Escriba aquí su respuesta...",
    "button_text": "Aceptar"
  }
];

const AnimatedForm = () => {
  const [previousStep, setPreviousStep] = useState(-1);
  const [currentStep, setCurrentStep] = useState(0);

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setPreviousStep(currentStep)
      setCurrentStep(currentStep - 1)

      setTimeout(() => {
        setPreviousStep(-1)
      }, 500)
    }
  }

  const handleNextStep = () => {
    if (currentStep < forms.length - 1) {
      setPreviousStep(currentStep)
      setCurrentStep(currentStep + 1)

      setTimeout(() => {
        setPreviousStep(-1)
      }, 500)
    }
  }

  const getClassName = (index) => {
    if (index === previousStep) {
      return 'exaggerated-fade-out'
    } else if (index === currentStep) {
      return 'exaggerated-fade-in'
    } else {
      return 'hidden'
    }
  }

  return (
    <section className="w-screen h-screen relative">
      {forms.map((form, index) => (
        <div key={ `animated-form-${index}` } 
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="w-screen flex items-start justify-center px-6">
            <div className={ `mt-2 mr-4 flex items-center ${getClassName(index)}` }>
              <p className="mr-1">{ index + 1 }</p> <MdArrowForward />
            </div>
            <div className={ `${getClassName(index)}` }>
              <h2 className="text-2xl mb-4">{ form.title }</h2>
              <p className="text-gray-500 text-lg mb-4">{ form.description }</p>
              <input
                type="text"
                placeholder={ form.hint_text }
                className={ `w-full text-2xl p-2 mb-4 h-10 outline-none border-gray-300 border-b 
                focus:border-black hover:border-black
                transition-border duration-200` } />
              <button onClick={ handleNextStep } 
                  className="w-auto flex items-center bg-gradient-to-tr from-blue-700 to-blue-500 text-white text-lg py-3 px-4 rounded-md shadow-md hover:shadow-lg transition duration-300 transform hover:scale-105">
                { form.button_text } <MdCheck />
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className="absolute bottom-0 left-0 mb-10 ml-10 md:ml-24">
        <button onClick={ handlePreviousStep }
          className={ `rounded-l-md bg-gradient-to-tr from-blue-600 to-blue-500 ${
          currentStep === 0 ? '' : 'hover:from-blue-500 hover:to-blue-400'
        }` }>
          <MdOutlineKeyboardArrowUp size={ 24 } 
            color={currentStep === 0 ? '#B2B2B2' : 'white' } />
        </button>
        <button onClick={ handleNextStep }
          className={ `rounded-r-md bg-gradient-to-bl from-blue-600 to-blue-500 ${
          currentStep === forms.length - 1 ? '' : 'hover:from-blue-500 hover:to-blue-400'
        }` }>
          <MdOutlineKeyboardArrowDown size={ 24 } 
            color={currentStep === forms.length - 1 ? '#B2B2B2' : 'white' } />
        </button>
      </div>
    </section>
  );
};

export default AnimatedForm;
