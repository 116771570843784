import React, { useState, useEffect } from 'react'

// TODO: develop footer links and stuff.

const FooterSection = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update screenWidth when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs once after initial render

  const elementClass = "pb-1 md:pb-0"
  const currentYear = new Date().getFullYear()
  const verticalDivider = () => { 
    if (screenWidth < 768) {
      return null
    }
    return <p className="text-gray-400 mx-2">|</p>
  }

  return (
    <footer className="mx-auto max-w-2xl">
      <hr className="mt-6 mb-4 border-gray-300"/>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex flex-col md:flex-row justify-start text-black text-xs">
          <a className={ elementClass } href="">Contacto</a>
          { verticalDivider() }
          <a className={ elementClass } href="">Aviso legal</a>
          { verticalDivider() }
          <a className={ elementClass } href="">Política de Cookies</a>
          { verticalDivider() }
          <a className={ elementClass } href="">Política de Privacidad</a>
        </div>
        <p className="text-gray-500 text-start mt-4 md:mt-0 text-xs">&copy; { currentYear } Arcal</p>
      </div>
    </footer>
  )
}

export default FooterSection