import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'

const CookiesConsent = () => {
  // Define a state to track the screen width
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  // Update the screen width state on window resize
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // 
  const [cookies, setCookie] = useCookies(["arcal_show_cookies_consent"])

  // Set a cookie to remember user's preference
  const handleAccept = () => {
    setCookie(
      "arcal_show_cookies_consent", 
      { accepted: true }, 
      { path: "/" }
    )
  }

  if (cookies.arcal_show_cookies_consent) {
    return null
  }

  // Determine the movement direction based on screen width.
  const isSmallDevice = () => {
    return screenWidth < 768
  }

  return (
    <div className="fixed bottom-0 left-0 w-full p-4 bg-gray-200 text-black">
      <div className="container mx-auto flex items-center justify-between">
        <p className="text-xs md:text-sm">
          Este sitio web utiliza cookies para ofrecerle la mejor experiencia posible.
          { isSmallDevice() ? <br /> : ' ' }
          <a href="/cookies-policy" className="underline">
            Ver más
          </a>
          .
        </p>
        <button
          className="ml-4 bg-gradient-to-tr from-blue-700 to-blue-500 hover:from-blue-800 hover:to-blue-600 text-white px-4 py-2 rounded"
          onClick={ handleAccept }>
          Aceptar
        </button>
      </div>
    </div>
  )
}

export default CookiesConsent
