import React from 'react'
import DOMPurify from 'dompurify'

const List = ({ forLandingPage = false, ordered = false, children = []}) => {
  return (
    <ul className={ `mx-auto text-balance pl-8 py-2 ${
        ordered ? 'list-decimal' : 'list-disc'
    } ${
        forLandingPage ? 'max-w-2xl pr-2' : ''
      }` }>
        {
            children.map((htmlContent, index) => {
                return <li key={ `list-${index}` } 
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent) }} />
            })
        }
    </ul>
  )
}

export default List