import React from 'react'
import PlainButton from './PlainButton'
import PrimaryButton from './PrimaryButton'
import Title from './Title'

const Hero = () => {
  return (
    <div className="w-screen bg-gradient-to-br from-blue-200 to-white flex flex-col lg:flex-row items-center justify-center">
        <div className="mt-40 lg:my-40">
          { /* Text content */}
          <div className="max-w-lg w-auto px-6">
              <Title ypadding={ false }>Hable inglés <em>fluido</em>. En tiempo récord.</Title>
              <div className="mt-2">
                  <Title tag="h3" ypadding={ false } semibold={ false }>Tendrá un nivel alto, podrá mantener conversaciones con cualquier nativo, su pronunciación será impecable y entenderá todo lo que lea y escuche.</Title>
              </div>
              <div className="flex mt-6">
                  <PrimaryButton href="/get-started">Get started</PrimaryButton>
                  <div className="ml-2">
                      <PlainButton href="/docs">Read the docs</PlainButton>
                  </div>
              </div>
          </div>
        </div>  

        <div className="mb-40 lg:my-40">
          { /* Image */}
          <div className="ml-6 mt-6">
            <img src="https://magazine.chrono24.com/cdn-cgi/image/f=auto,metadata=none,fit=cover,q=65,w=1200,h=600,dpr=2.0/2022/04/Rolex-Opinion-21-scaled.jpg" alt="Hero Image" className="h-96 w-auto object-cover" />
          </div>
        </div>
    </div>
  )
}

export default Hero