import React from 'react'
import { Helmet } from 'react-helmet'

const HeadSection = ({ data }) => {
  return (
    <Helmet>
      <title>{ data.title }</title>
      <meta name="description" content={ data.description } />
      <link rel="canonical" href={ data.url } />

      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content={ data.type === "article" ? "article" : "website" } />
      <meta property="og:title" content={ data.title } />
      <meta property="og:description" content={ data.description } />
      <meta property="og:url" content={ data.url } />
      <meta property="og:site_name" content="Arcal" />
      <meta property="og:updated_time" content={ data.updatedTime } />
      <meta property="og:image" content={ data.image } />
      <meta property="og:image:secure_url" content={ data.image } />
      <meta property="og:image:width" content={ data.imageWidth } />
      <meta property="og:image:height" content={ data.imageHeight } />
      <meta property="og:image:alt" content={ data.imageAlt } />
      <meta property="og:image:type" content={ data.imageType } />
      <meta property="article:published_time" content={ data.publishedTime } />
      <meta property="article:modified_time" content={ data.modifiedTime } />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ data.title } />
      <meta name="twitter:description" content={ data.description } />
      <meta name="twitter:site" content={ data.twitterSite } />
      <meta name="twitter:creator" content={ data.twitterCreator } />
      <meta name="twitter:image" content={ data.image } />
      <meta name="twitter:label1" content={ data.twitterLabel1 } />
      <meta name="twitter:data1" content={ data.twitterData1 } />
      <meta name="twitter:label2" content={ data.twitterLabel2 } />
      <meta name="twitter:data2" content={ data.twitterData2 } />

      {/* TODO: Google Ads account, Google Analytics, Facebook Pixel... */}
    </Helmet>
  )
}

export default HeadSection