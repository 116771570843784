import React from 'react'
import DOMPurify from 'dompurify'

const Paragraph = ({ forLandingPage = false, asHtml = null, children }) => {
  if (asHtml !== null) {
    return (
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(asHtml) }} 
          className={ `mx-auto pb-2 text-balance ${
            forLandingPage ? 'max-w-2xl px-6' : ''
          }` } />
    )
  }

  return (
    <p className={ `mx-auto pb-2 text-balance ${
          forLandingPage ? 'max-w-2xl px-6' : ''
        }` }>
      { children }
    </p>
  )
}

export default Paragraph