import React from 'react'
import Title from './Title'

const relatedArticles = [
    {
        "imageSrc": "https://magazine.chrono24.com/cdn-cgi/image/f=auto,metadata=none,fit=cover,q=65,w=1200,h=600,dpr=2.0/2022/04/Rolex-Opinion-21-scaled.jpg", 
        "alt": "altText",
        "title": "Rolex Chrono 24", 
        "url": "/chrono24"
    },
    {
        "imageSrc": "https://magazine.chrono24.com/cdn-cgi/image/f=auto,metadata=none,fit=cover,q=65,w=1200,h=600,dpr=2.0/2022/04/Rolex-Opinion-21-scaled.jpg", 
        "alt": "altText",
        "title": "Rolex Chrono 24", 
        "url": "/chrono24"
    },
    {
        "imageSrc": "https://magazine.chrono24.com/cdn-cgi/image/f=auto,metadata=none,fit=cover,q=65,w=1200,h=600,dpr=2.0/2022/04/Rolex-Opinion-21-scaled.jpg", 
        "alt": "altText",
        "title": "Rolex Chrono 24", 
        "url": "/chrono24"
    },
    {
        "imageSrc": "https://magazine.chrono24.com/cdn-cgi/image/f=auto,metadata=none,fit=cover,q=65,w=1200,h=600,dpr=2.0/2022/04/Rolex-Opinion-21-scaled.jpg", 
        "alt": "altText",
        "title": "Rolex Chrono 24", 
        "url": "/chrono24"
    }
]

const RelatedArticles = () => {
  return (
    <section>
        <Title tag="h2">Artículos relacionados</Title>
        <div className="flex flex-wrap mt-4">
            {relatedArticles.map((article, index) => {
                return <a key={ `related-articles-a-${index}` } className="flex flex-col w-auto md:w-1/4 md:mr-8 mb-6" href="#">
                    <img key={ `related-articles-img-${index}` } className="mb-2" src={ article.imageSrc } alt={ article.alt } />
                    <Title key={ `related-articles-title-${index}` } tag="h3" ypadding={ false }>{ article.title }</Title>
                </a>
            })}
        </div>
    </section>
  )
}

export default RelatedArticles