import React from 'react'

const Title = ({ tag, center = false, xpadding = false, ypadding = true, semibold = true, children }) => {
  if (!["h1", "h2", "h3", "h4", "h5", "h6"].includes(tag)) {
    tag = "h1"
  }

  const getFontSize = (input) => {
    switch (input) {
        case "h1": 
            return "text-5xl"
        case "h2":
            return "text-4xl"
        case "h3": 
            return "text-xl"
        case "h4": 
            return "text-lg" 
        case "h5": 
            return "text-base"
        case "h6": 
            return "text-sm"
        default:
            return ""
    }
  }
  
  const commonClassNames = (`${
    semibold ? 'font-semibold' : ''
  } ${
    center ? 'text-center' : 'text-start'
  } ${
    xpadding ? 'px-6' : ''
  } ${
    ypadding ? 'mt-6 mb-2' : ''
  }`)

  if (tag === "h1") {
    return (
        <h1 className={ `${ getFontSize(tag) } ${ commonClassNames }` }>
            { children }
        </h1>
    )
  } else if (tag === "h2") {
    return (
        <h2 className={ `${ getFontSize(tag) } ${ commonClassNames }` }>
            { children }
        </h2>
    )
  } else if (tag === "h3") {
    return (
        <h3 className={ `${ getFontSize(tag) } ${ commonClassNames }` }>
            { children }
        </h3>
    )
  } else if (tag === "h4") {
    return (
        <h4 className={ `${ getFontSize(tag) } ${ commonClassNames }` }>
            { children }
        </h4>
    )
  } else if (tag === "h5") {
    return (
        <h5 className={ `${ getFontSize(tag) } ${ commonClassNames }` }>
            { children }
        </h5>
    )
  }

  return (
      <h6 className={ `${ getFontSize(tag) } ${ commonClassNames }` }>
          { children }
      </h6>
  )
}

export default Title