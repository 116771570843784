import React from 'react'

const PrimaryButton = ({ href, children }) => {
  return (
    <center>
        <a href={ href } className="inline-block w-auto bg-gradient-to-tr from-blue-700 to-blue-500 text-white text-lg py-3 px-4 rounded-md shadow-md hover:shadow-lg transition duration-300 transform hover:scale-105">
            { children }
        </a>
    </center>
  )
}

export default PrimaryButton