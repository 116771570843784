import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Paragraph from '../components/Paragraph'
import Title from '../components/Title'
import LegendaryImage from '../components/LegendaryImage'
import HeadSection from '../sections/HeadSection'
import FooterSection from '../sections/FooterSection'
import RelatedArticles from '../components/RelatedArticles'
import List from '../components/List'

const blocks = [
  {type: "title", tag: "h1", content: "This is the H1 header"},
  {type: "paragraph", content: "A very good and <em>original</em> Lorem ipsum here would be awesome."},
  {type: "list", content: ["Apple", "Google", "Microsoft"]},
  {type: "list", ordered: true, content: ["Apple", "Google", "Microsoft"]},
  {type: "title", tag: "h2", content: "This is the H2 header"},
  {type: "paragraph", content: "A very good and <strong>original</strong> Lorem ipsum here would be awesome."},
  {type: "image", legend: "Leyenda estándar pero mucho más grande para ver hasta dónde llegan los límites de padding.", alt: "rolex chrono", src: "https://magazine.chrono24.com/cdn-cgi/image/f=auto,metadata=none,fit=cover,q=65,w=1200,h=600,dpr=2.0/2022/04/Rolex-Opinion-21-scaled.jpg"},
  {type: "paragraph", content: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa illo tempore modi. Consectetur sint cupiditate amet ipsam perspiciatis? Animi qui modi impedit quasi eligendi ducimus enim incidunt deleniti odit facilis!"},
]

const jsonMetadataString = `{
  "title": "Academia de inglés | Arcal",
  "description": "Descripción de estas que van en los resultados de búsqueda de Google y no deberían exceder los 120 caracteres.",
  "robots": "follow, index",
  "type": "article",
  "url": "https://arcalapp.com/"
}`

const Article = () => {
  const [post, setPost] = useState({});
  const { url } = useParams();

  useEffect(() => {
    const getPost = async () => {
      const resp = await fetch(`https://google.com/`)
      console.log(`${resp.body}`)
      const postResp = await resp.text()
      console.log(`${postResp}`)
      setPost(postResp);
    };

    getPost();
  }, [url]);

  if (!Object.keys(post).length) {
    console.log(`gugugaga ${url}`)
    return <div />
  }

  return (
    <div className="mx-auto max-w-2xl px-6 pb-4">
      <HeadSection data={ JSON.parse(jsonMetadataString) } />
      {
        blocks.map((block, index) => {
          if (block.type === "title") {
            return <Title key={ `title-title-${index}` } 
                          tag={ block.tag } 
                          children={ block.content } />
          } else if (block.type === "paragraph") {
            return <Paragraph key={ `title-p-${index}` } 
                              asHtml={ block.content } />
          } else if (block.type === "image") {
            return <LegendaryImage key={ `title-img-${index}` } 
                          alt={ block.alt } 
                          src={ block.src } 
                          legend={ block.legend } />
          } else if (block.type === "list") {
            return <List key={ `list-${index}` } 
                          ordered={ block.ordered }
                          children={ block.content } />
          }
          return null
        })
      }
      <RelatedArticles />
      <FooterSection />
    </div>
  )
}

export default Article