import React from 'react'

const PlainButton = ({ href, children }) => {
  return (
    <center>
        <a href={ href } className="inline-block w-auto bg-gray-300 text-black text-lg py-3 px-4 rounded-md shadow-md hover:shadow-lg transition duration-300 transform hover:scale-105">
            { children }
        </a>
    </center>
  )
}

export default PlainButton