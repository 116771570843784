import React from 'react'

const LegendaryImage = ({ src = "", alt = "", legend }) => {
  return (
    <div className="w-auto mb-6">
        <img src={ src } alt={ alt } />
        <center className="text-gray-500 text-sm mt-1 px-2">
          <em>{ legend }</em>
        </center>
    </div>
  )
}

export default LegendaryImage