import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const Error404 = () => {
  return (
    <div>
      <Helmet>
        <meta name="robots" content="follow, noindex" />
      </Helmet>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="inline-block w-auto bg-gradient-to-tr from-blue-700 to-blue-500 text-white text-lg py-3 px-4 rounded-md shadow-md hover:shadow-lg transition duration-300 transform hover:scale-105">
              Go back home
            </Link>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Error404