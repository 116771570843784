import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'

import Hero from './components/Hero.jsx'
import Paragraph from './components/Paragraph.jsx'
import PlainButton from './components/PlainButton.jsx'
import ScrollFadeInMove from './animations/ScrollFadeInMove.jsx'
import Navbar from './components/Navbar.jsx'
import DetailedProductFeature from './components/DetailedProductFeature.jsx'
import Article from './templates/Article.jsx'
import RelatedArticles from './components/RelatedArticles.jsx'
import CookiesConsent from './components/CookiesConsent.jsx'
import AnimatedForm from './components/AnimatedForm.jsx'
import ProductFeatures from './components/ProductFeatures.jsx'
import Error404 from './templates/Error404.jsx'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Hero />,
  },
  {
    path: "/contact",
    element: <AnimatedForm />,
  },
  {
    path: "/:url",
    element: <Article />,
  },
  {
    path: "*",
    element: <Error404 />,
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={ router } />
    <CookiesConsent />
  </React.StrictMode>
)

/*
const t = 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Neque aut unde culpa hic rerum eveniet odio temporibus rem beatae cum similique, nisi laudantium, molestias in soluta incidunt labore, nulla dignissimos.'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Navbar />
    <Hero />
    <Article />
    <br />
    <PlainButton href="/asd" children="uhu" />
    <br />
    <DetailedProductFeature imageFirst={ true }>Lorem ipsumae</DetailedProductFeature>
    <DetailedProductFeature>Lorem ipsumae</DetailedProductFeature>
    <Paragraph>{ t }</Paragraph>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
    <ScrollFadeInMove>
      <Paragraph>{ t }</Paragraph>
    </ScrollFadeInMove>
  </React.StrictMode>,
)
*/